import { render, staticRenderFns } from "./Epad.vue?vue&type=template&id=6c8e8376&scoped=true&"
import script from "./Epad.vue?vue&type=script&lang=js&"
export * from "./Epad.vue?vue&type=script&lang=js&"
import style0 from "./Epad.vue?vue&type=style&index=0&id=6c8e8376&lang=scss&scoped=true&"
import style1 from "./Epad.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c8e8376",
  null
  
)

export default component.exports